const Creativitas = [
  {
    name: 'https://www.fiverr.com/creativitas',
    title: 'Make limousine and car service website with new technology',
    description:
      'New limousine and car service website template free download source code full built with react.',
    create: '👉 Create Website Now',
    link: 'https://www.fiverr.com/creativitas/create-your-website-with-new-technology',
  },
];

export default Creativitas;
